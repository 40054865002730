import React from 'react';
import PropTypes from 'prop-types';
import { Icon, IconButton } from '@mui/material';
import HelpIcon from '@mui/icons-material/Help';
import useGlobalModal from 'hooks/useGlobalModal';

const GlobalModalHelpButton = ({ title, content, button = <HelpIcon />, sx = {} }) => {
    const { openGlobalModal } = useGlobalModal();

    const handleClick = () => {
        openGlobalModal({ title, content });
    };

    return (
        <Icon color="primary" onClick={handleClick} sx={{ height: '100%', display: 'inline-flex', ...sx }}>
            {button}
        </Icon>
    );
};

GlobalModalHelpButton.propTypes = {
    title: PropTypes.string.isRequired,
    content: PropTypes.string.isRequired,
    button: PropTypes.any,
    sx: PropTypes.object
};

export default GlobalModalHelpButton;
