/* eslint-disable consistent-return , no-else-return */

import useOnePageSurveyStore from 'zustand_store/useOnePageSurveyStore';
import { v4 as uuidv4 } from 'uuid';
import QuestionService from 'services/question.service';
import { useParams } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import snackcaseToPascalcase from 'utils/helperFunctions';
import { first } from 'lodash';

const useQuestionSubmitHandlerController = () => {
    const SET_CURRENT_QUESTION = useOnePageSurveyStore((state) => state.SET_CURRENT_QUESTION);
    const UPDATE_QUESTION = useOnePageSurveyStore((state) => state.UPDATE_QUESTION);
    const { surveyToken, questionId } = useParams();

    const currentQuestion = useOnePageSurveyStore((state) => state.currentQuestion);
    const { enqueueSnackbar } = useSnackbar();

    const ShortQuestionSubmitHandler = async (values) => {
        const formData = {
            ...values,
            required: Number(values.meta.required),
            is_active: Number(values.meta.is_active),
            question_type_id: currentQuestion?.question_type_id,
            identifier: values?.identifier
                ? snackcaseToPascalcase(values?.identifier)
                : snackcaseToPascalcase(currentQuestion?.meta?.identifier),
            meta: {
                ...values.meta,
                identifier: values?.identifier
                    ? snackcaseToPascalcase(values?.identifier)
                    : snackcaseToPascalcase(currentQuestion?.meta?.identifier)
            }
        };
        const createdOrUpdatedQuestionId = currentQuestion?.id;

        console.log('formik Main####', formData);

        const { data: question } = await QuestionService.createOrUpdate(surveyToken, formData);

        // SET_CURRENT_QUESTION(question);
        UPDATE_QUESTION(createdOrUpdatedQuestionId, question);
        enqueueSnackbar('Question saved!', { variant: 'success', autoHideDuration: 3000 });

        return { question };
    };

    const LongQuestionSubmitHandler = async (values) => {
        const formData = {
            ...values,
            required: Number(values.meta.required),
            is_active: Number(values.meta.is_active),
            question_type_id: currentQuestion?.question_type_id,
            identifier: values?.identifier
                ? snackcaseToPascalcase(values?.identifier)
                : snackcaseToPascalcase(currentQuestion?.meta?.identifier),
            meta: {
                ...values.meta,
                identifier: values?.identifier
                    ? snackcaseToPascalcase(values?.identifier)
                    : snackcaseToPascalcase(currentQuestion?.meta?.identifier)
            }
        };
        const createdOrUpdatedQuestionId = currentQuestion?.id;

        const { data: question } = await QuestionService.createOrUpdate(surveyToken, formData);

        // SET_CURRENT_QUESTION(question);
        UPDATE_QUESTION(createdOrUpdatedQuestionId, question);
        enqueueSnackbar('Question saved!', { variant: 'success', autoHideDuration: 3000 });

        return { question };
    };

    const DropdownQuestionSubmitHandler = async (values) => {
        const formData = {
            ...values,
            choices: values.choices
                ?.split('\n')
                ?.filter((choice) => choice !== '')
                // eslint-disable-next-line object-shorthand
                ?.map((choice) => ({ choice: choice })),
            required: Number(values.meta.required),
            is_active: Number(values.meta.is_active),
            question_type_id: currentQuestion?.question_type_id,
            identifier: snackcaseToPascalcase(values?.question_type?.identifier)
        };

        const createdOrUpdatedQuestionId = currentQuestion?.id;

        console.log('formData', formData);

        const { data: question } = await QuestionService.createOrUpdate(surveyToken, formData);
        // SET_CURRENT_QUESTION(question);
        UPDATE_QUESTION(createdOrUpdatedQuestionId, question);
        enqueueSnackbar('Question saved!', { variant: 'success', autoHideDuration: 3000 });
        return { question };
    };

    const BooleanQuestionSubmitHandler = async (values) => {
        const formData = {
            ...values,
            choices: [],
            required: Number(values.meta.required),
            is_active: Number(values.meta.is_active),
            question_type_id: currentQuestion?.question_type_id,
            identifier: values?.identifier
                ? snackcaseToPascalcase(values?.identifier)
                : snackcaseToPascalcase(currentQuestion?.meta?.identifier),
            // identifier: snackcaseToPascalcase(currentQuestion.identifier),
            meta: {
                ...values.meta,
                identifier: values?.identifier
                    ? snackcaseToPascalcase(values?.identifier)
                    : snackcaseToPascalcase(currentQuestion?.meta?.identifier)
            }
        };

        const createdOrUpdatedQuestionId = currentQuestion?.id;

        const { data: question } = await QuestionService.createOrUpdate(surveyToken, formData);

        // SET_CURRENT_QUESTION(question);
        UPDATE_QUESTION(createdOrUpdatedQuestionId, question);
        enqueueSnackbar('Question saved!', { variant: 'success', autoHideDuration: 3000 });
        return { question };
    };

    const MatrixQuestionSubmitHandler = async (values) => {
        const formData = {
            ...values,
            required: Number(values.meta.required),
            is_active: Number(values.meta.is_active),
            question_type_id: currentQuestion.question_type_id,
            identifier: values?.identifier
                ? snackcaseToPascalcase(values?.identifier)
                : snackcaseToPascalcase(currentQuestion?.meta?.identifier),
            meta: {
                ...values.meta,
                identifier: values?.identifier
                    ? snackcaseToPascalcase(values?.identifier)
                    : snackcaseToPascalcase(currentQuestion?.meta?.identifier),
                rowColumnData: {
                    rows: values?.meta?.tableData?.rows?.map(({ text }) => ({ text })),
                    cols: values?.meta?.tableData?.cols?.map(({ text }) => ({ text }))
                }
            }
        };

        const createdOrUpdatedQuestionId = currentQuestion?.id;

        const { data: question } = await QuestionService.createOrUpdate(surveyToken, formData);

        // SET_CURRENT_QUESTION(question);
        UPDATE_QUESTION(createdOrUpdatedQuestionId, question);
        enqueueSnackbar('Question saved!', { variant: 'success', autoHideDuration: 3000 });
        return { question };
    };

    const MatrixDropdownQuestionSubmitHandler = async (values) => {
        let error = false;
        values?.meta?.mainSourceData?.every((data) => {
            // Checks if there is any empty string in object element
            if (Object.values(data).some((x) => x === null || x === '')) {
                enqueueSnackbar('Please enter value in every dropdown!', { variant: 'error', autoHideDuration: 3000 });
                error = true;
                return false;
            }
            return true;
        });

        if (!values?.meta?.mainSourceData) {
            enqueueSnackbar('Please enter value in every dropdown!', { variant: 'error', autoHideDuration: 3000 });

            error = true;
        }

        if (!error) {
            const formData = {
                ...values,
                required: Number(values.meta.required),
                is_active: Number(values.meta.is_active),
                question_type_id: currentQuestion.question_type_id,
                identifier: values?.identifier
                    ? snackcaseToPascalcase(values?.identifier)
                    : snackcaseToPascalcase(currentQuestion?.meta?.identifier),
                choices: '',
                meta: {
                    ...values.meta,
                    identifier: values?.identifier
                        ? snackcaseToPascalcase(values?.identifier)
                        : snackcaseToPascalcase(currentQuestion?.meta?.identifier),
                    rowColumnData: {
                        rows: values?.meta?.tableData.rows?.map(({ text }) => ({ text })),
                        cols: values?.meta?.tableData.cols?.map(({ text }) => ({ text })),
                        data: values?.meta?.mainSourceData
                    }
                }
            };

            const createdOrUpdatedQuestionId = currentQuestion?.id;

            const { data: question } = await QuestionService.createOrUpdate(surveyToken, formData);
            // SET_CURRENT_QUESTION(question);
            UPDATE_QUESTION(createdOrUpdatedQuestionId, question);
            enqueueSnackbar('Question saved!', { variant: 'success', autoHideDuration: 3000 });

            return { question };
        }

        return { question: values };
    };

    const MatrixDynamicQuestionSubmitHandler = async (values) => {
        console.log('check values', values);
        const formData = {
            ...values,
            required: Number(values.meta.required),
            is_active: Number(values.meta.is_active),
            question_type_id: currentQuestion.question_type_id,
            identifier: values?.identifier
                ? snackcaseToPascalcase(values?.identifier)
                : snackcaseToPascalcase(currentQuestion?.meta?.identifier),
            choices: '',
            meta: {
                ...values.meta,
                identifier: values?.identifier
                    ? snackcaseToPascalcase(values?.identifier)
                    : snackcaseToPascalcase(currentQuestion?.meta?.identifier),
                rowColumnData: {
                    rows: values?.meta?.tableData.rows?.map(({ text }) => ({ text })),
                    cols: values?.meta?.tableData.cols?.map(({ text }) => ({ text }))
                }
            }
        };

        const createdOrUpdatedQuestionId = currentQuestion?.id;

        const { data: question } = await QuestionService.createOrUpdate(surveyToken, formData);
        // SET_CURRENT_QUESTION(question);
        UPDATE_QUESTION(createdOrUpdatedQuestionId, question);
        enqueueSnackbar('Question saved!', { variant: 'success', autoHideDuration: 3000 });
        return { question };
    };

    const McqQuestionSubmitHandler = async (values) => {
        console.log('MCq values', values);
        if (!values.choices) {
            enqueueSnackbar('You must add a choice!', { variant: 'error', autoHideDuration: 3000 });
            return false;
        }

        const formData = {
            ...values,
            choices: values.choices,
            required: Number(values.meta.required),
            is_active: Number(values.meta.is_active),
            question_type_id: currentQuestion?.question_type_id,
            identifier: values?.identifier
                ? snackcaseToPascalcase(values?.identifier)
                : snackcaseToPascalcase(currentQuestion?.meta?.identifier),
            meta: {
                ...values.meta,
                choices: values.choices,
                identifier: values?.identifier
                    ? snackcaseToPascalcase(values?.identifier)
                    : snackcaseToPascalcase(currentQuestion?.meta?.identifier)
            }
        };

        const createdOrUpdatedQuestionId = currentQuestion?.id;

        const { data: question } = await QuestionService.createOrUpdate(surveyToken, formData);
        // SET_CURRENT_QUESTION(question);
        UPDATE_QUESTION(createdOrUpdatedQuestionId, question);
        enqueueSnackbar('Question saved!', { variant: 'success', autoHideDuration: 3000 });
        return { question };
    };

    const RatingQuestionSubmitHandler = async (values) => {
        const formData = {
            ...values,
            required: Number(values.meta.required),
            is_active: Number(values.meta.is_active),
            question_type_id: currentQuestion.question_type_id,
            identifier: values?.identifier
                ? snackcaseToPascalcase(values?.identifier)
                : snackcaseToPascalcase(currentQuestion?.meta?.identifier),
            meta: {
                ...values.meta,
                identifier: values?.identifier
                    ? snackcaseToPascalcase(values?.identifier)
                    : snackcaseToPascalcase(currentQuestion?.meta?.identifier)
            }
        };

        const createdOrUpdatedQuestionId = currentQuestion?.id;

        const { data: question } = await QuestionService.createOrUpdate(surveyToken, formData);
        // SET_CURRENT_QUESTION(question);
        UPDATE_QUESTION(createdOrUpdatedQuestionId, question);
        enqueueSnackbar('Question saved!', { variant: 'success', autoHideDuration: 3000 });

        return { question };
    };

    const FilesUploadingQuestionSubmitHandler = async (values) => {
        const formData = {
            ...values,
            required: Number(values.meta.required),
            is_active: Number(values.meta.is_active),
            question_type_id: currentQuestion.question_type_id,
            identifier: values?.identifier
                ? snackcaseToPascalcase(values?.identifier)
                : snackcaseToPascalcase(currentQuestion?.meta?.identifier),
            survey_token: surveyToken,
            meta: {
                ...values.meta,
                identifier: values?.identifier
                    ? snackcaseToPascalcase(values?.identifier)
                    : snackcaseToPascalcase(currentQuestion?.meta?.identifier)
            }
        };

        const createdOrUpdatedQuestionId = currentQuestion?.id;

        const { data: question } = await QuestionService.createOrUpdate(surveyToken, formData);
        // SET_CURRENT_QUESTION(question);
        UPDATE_QUESTION(createdOrUpdatedQuestionId, question);
        enqueueSnackbar('Question saved!', { variant: 'success', autoHideDuration: 3000 });

        return { question };
    };

    const ImagePickerQuestionSubmitHandler = async (values) => {
        const formData = {
            ...values,
            choices: values.choices,
            required: Number(values.meta.required),
            is_active: Number(values.meta.is_active),
            question_type_id: currentQuestion.question_type_id,
            identifier: values?.identifier
                ? snackcaseToPascalcase(values?.identifier)
                : snackcaseToPascalcase(currentQuestion?.meta?.identifier),
            meta: {
                ...values.meta,
                identifier: values?.identifier
                    ? snackcaseToPascalcase(values?.identifier)
                    : snackcaseToPascalcase(currentQuestion?.meta?.identifier)
            }
        };

        // deletes redundant Choice data
        delete formData.choices;

        // Creates All choices
        if (values.choices?.length > 1 && (values.choices[0].choice || values.choices[0].mainImage)) {
            const createdOrUpdatedQuestionId = currentQuestion?.id;

            if (typeof values.id === 'string') {
                const createdQuestion = await QuestionService.create(surveyToken, formData);

                // eslint-disable-next-line consistent-return
                const choicesPromise = values.choices.map(async (choice) => {
                    if (choice.choice || choice.mainImage) {
                        const data = {
                            choiceText: choice.choice,
                            choiceImage: choice.mainImage,
                            meta: {
                                image: choice.mainImage
                            }
                        };

                        return QuestionService.createSingleimageChoice(surveyToken, createdQuestion.data.id, data);
                    }
                });

                // Wait for all promises to resolve
                const choicesPromiseResult = await Promise.all(choicesPromise);
                const allChoicesOfQuestion = first(choicesPromiseResult.map((question) => question.data.choices));
                const ultimateQuestionDataWithChoices = { ...createdQuestion.data, choices: allChoicesOfQuestion };

                // SET_CURRENT_QUESTION(ultimateQuestionDataWithChoices);
                UPDATE_QUESTION(createdOrUpdatedQuestionId, ultimateQuestionDataWithChoices);
                enqueueSnackbar('Question saved!', { variant: 'success', autoHideDuration: 3000 });
                return { question: ultimateQuestionDataWithChoices };
            } else if (typeof values.id === 'number') {
                // update question data
                const { data: updatedQuestion } = await QuestionService.update(surveyToken, values.id, formData);

                // Creates New Choices one by one if it is a new choice
                values.choices = values.choices.map((choice) => {
                    const data = {
                        ...choice,
                        choiceText: choice.choice,
                        choiceImage: choice.mainImage
                    };
                    return data;
                });

                // Updates Existing Choices
                const { data: updatedQuestionWithChocies } = await QuestionService.updateImageChoices(
                    surveyToken,
                    currentQuestion?.id,
                    values
                );

                const ultimateQuestionDataWithChoices = { ...updatedQuestion, choices: updatedQuestionWithChocies.choices };

                // SET_CURRENT_QUESTION(ultimateQuestionDataWithChoices);

                UPDATE_QUESTION(createdOrUpdatedQuestionId, ultimateQuestionDataWithChoices);

                enqueueSnackbar('Question saved!', { variant: 'success', autoHideDuration: 3000 });

                return { question: ultimateQuestionDataWithChoices };
            }
        } else {
            enqueueSnackbar('You must add two choices!', { variant: 'error', autoHideDuration: 3000 });
        }
    };

    const PhoneNumberQuestionSubmitHandler = async (values) => {
        const formData = {
            ...values,
            required: Number(values.meta.required),
            is_active: Number(values.meta.is_active),
            question_type_id: currentQuestion.question_type_id,
            identifier: values?.identifier
                ? snackcaseToPascalcase(values?.identifier)
                : snackcaseToPascalcase(currentQuestion?.meta?.identifier),
            meta: {
                ...values.meta,
                identifier: values?.identifier
                    ? snackcaseToPascalcase(values?.identifier)
                    : snackcaseToPascalcase(currentQuestion?.meta?.identifier)
            }
        };

        const createdOrUpdatedQuestionId = currentQuestion?.id;

        const { data: question } = await QuestionService.createOrUpdate(surveyToken, formData);
        // SET_CURRENT_QUESTION(question);
        UPDATE_QUESTION(createdOrUpdatedQuestionId, question);
        enqueueSnackbar('Question saved!', { variant: 'success', autoHideDuration: 3000 });
        return { question };
    };

    const EmailQuestionSubmitHandler = async (values) => {
        const formData = {
            ...values,
            required: Number(values.meta.required),
            is_active: Number(values.meta.is_active),
            question_type_id: currentQuestion.question_type_id,
            identifier: values?.identifier
                ? snackcaseToPascalcase(values?.identifier)
                : snackcaseToPascalcase(currentQuestion?.meta?.identifier),
            meta: {
                ...values.meta,
                identifier: values?.identifier
                    ? snackcaseToPascalcase(values?.identifier)
                    : snackcaseToPascalcase(currentQuestion?.meta?.identifier)
            }
        };

        const createdOrUpdatedQuestionId = currentQuestion?.id;

        const { data: question } = await QuestionService.createOrUpdate(surveyToken, formData);
        // SET_CURRENT_QUESTION(question);
        UPDATE_QUESTION(createdOrUpdatedQuestionId, question);
        enqueueSnackbar('Question saved!', { variant: 'success', autoHideDuration: 3000 });

        return { question };
    };

    const OpinionScaleQuestionSubmitHandler = async (values) => {
        const formData = {
            ...values,
            required: Number(values.meta.required),
            is_active: Number(values.meta.is_active),
            question_type_id: currentQuestion.question_type_id,
            identifier: values?.identifier
                ? snackcaseToPascalcase(values?.identifier)
                : snackcaseToPascalcase(currentQuestion?.meta?.identifier),
            meta: {
                ...values.meta,
                identifier: values?.identifier
                    ? snackcaseToPascalcase(values?.identifier)
                    : snackcaseToPascalcase(currentQuestion?.meta?.identifier)
            }
        };

        const createdOrUpdatedQuestionId = currentQuestion?.id;

        const { data: question } = await QuestionService.createOrUpdate(surveyToken, formData);
        // SET_CURRENT_QUESTION(question);
        UPDATE_QUESTION(createdOrUpdatedQuestionId, question);
        enqueueSnackbar('Question saved!', { variant: 'success', autoHideDuration: 3000 });
        return { question };
    };

    const CountryQuestionSubmitHandler = async (values) => {
        const formData = {
            ...values,
            // choices: values.choices.split('\n').filter((choice) => choice !== '').map(choice => ({'choice' : choice})),
            required: Number(values.meta.required),
            is_active: Number(values.meta.is_active),
            question_type_id: currentQuestion?.question_type_id,
            identifier: values?.identifier
                ? snackcaseToPascalcase(values?.identifier)
                : snackcaseToPascalcase(currentQuestion?.meta?.identifier),
            meta: {
                ...values.meta,
                identifier: values?.identifier
                    ? snackcaseToPascalcase(values?.identifier)
                    : snackcaseToPascalcase(currentQuestion?.meta?.identifier)
            }
        };

        const createdOrUpdatedQuestionId = currentQuestion?.id;

        const { data: question } = await QuestionService.createOrUpdate(surveyToken, formData);
        // SET_CURRENT_QUESTION(question);
        UPDATE_QUESTION(createdOrUpdatedQuestionId, question);
        enqueueSnackbar('Question saved!', { variant: 'success', autoHideDuration: 3000 });

        return { question };
    };

    const StateQuestionSubmitHandler = async (values) => {
        const formData = {
            ...values,
            // choices: values.choices.split('\n').filter((choice) => choice !== '').map(choice => ({'choice' : choice})),
            required: Number(values.meta.required),
            is_active: Number(values.meta.is_active),
            question_type_id: currentQuestion.question_type_id,
            identifier: values?.identifier
                ? snackcaseToPascalcase(values?.identifier)
                : snackcaseToPascalcase(currentQuestion?.meta?.identifier),
            meta: {
                ...values.meta,
                identifier: values?.identifier
                    ? snackcaseToPascalcase(values?.identifier)
                    : snackcaseToPascalcase(currentQuestion?.meta?.identifier)
            }
        };

        const createdOrUpdatedQuestionId = currentQuestion?.id;

        const { data: question } = await QuestionService.createOrUpdate(surveyToken, formData);
        // SET_CURRENT_QUESTION(question);
        UPDATE_QUESTION(createdOrUpdatedQuestionId, question);
        enqueueSnackbar('Question saved!', { variant: 'success', autoHideDuration: 3000 });
        return { question };
    };

    const MessageQuestionSubmitHandler = async (values, formik) => {
        const formData = {
            ...values,
            required: Number(values.meta.required),
            is_active: Number(values.meta.is_active),
            question_type_id: currentQuestion.question_type_id,
            identifier: values?.identifier
                ? snackcaseToPascalcase(values?.identifier)
                : snackcaseToPascalcase(currentQuestion?.meta?.identifier),
            meta: {
                ...values.meta,
                identifier: values?.identifier
                    ? snackcaseToPascalcase(values?.identifier)
                    : snackcaseToPascalcase(currentQuestion?.meta?.identifier)
            }
        };
        const createdOrUpdatedQuestionId = currentQuestion?.id;
        const { data: question } = await QuestionService.createOrUpdate(surveyToken, formData);
        // SET_CURRENT_QUESTION(question);
        UPDATE_QUESTION(createdOrUpdatedQuestionId, question);
        enqueueSnackbar('Question saved!', { variant: 'success', autoHideDuration: 3000 });

        if (question.order !== 1) {
            formik.setFieldValue('meta.welcome_screen', false);
        }

        return { question };
    };

    const NameQuestionSubmitHandler = async (values) => {
        const formData = {
            ...values,
            required: Number(values.meta.required),
            is_active: Number(values.meta.is_active),
            question_type_id: currentQuestion.question_type_id,
            identifier: values?.identifier
                ? snackcaseToPascalcase(values?.identifier)
                : snackcaseToPascalcase(currentQuestion?.meta?.identifier),
            meta: {
                ...values.meta,
                identifier: values?.identifier
                    ? snackcaseToPascalcase(values?.identifier)
                    : snackcaseToPascalcase(currentQuestion?.meta?.identifier)
            }
        };
        const createdOrUpdatedQuestionId = currentQuestion?.id;

        const { data: question } = await QuestionService.createOrUpdate(surveyToken, formData);
        // SET_CURRENT_QUESTION(question);
        UPDATE_QUESTION(createdOrUpdatedQuestionId, question);
        enqueueSnackbar('Question saved!', { variant: 'success', autoHideDuration: 3000 });
        return { question };
    };

    const DateQuestionSubmitHandler = async (values) => {
        const formData = {
            ...values,
            required: Number(values.meta.required),
            is_active: Number(values.meta.is_active),
            question_type_id: currentQuestion.question_type_id,
            identifier: values?.identifier
                ? snackcaseToPascalcase(values?.identifier)
                : snackcaseToPascalcase(currentQuestion?.meta?.identifier),
            meta: {
                ...values.meta,
                identifier: values?.identifier
                    ? snackcaseToPascalcase(values?.identifier)
                    : snackcaseToPascalcase(currentQuestion?.meta?.identifier)
            }
        };
        const createdOrUpdatedQuestionId = currentQuestion?.id;

        const { data: question } = await QuestionService.createOrUpdate(surveyToken, formData);
        // SET_CURRENT_QUESTION(question);
        UPDATE_QUESTION(createdOrUpdatedQuestionId, question);
        enqueueSnackbar('Question saved!', { variant: 'success', autoHideDuration: 3000 });

        return { question };
    };

    const SubmitHandlerFunctionMap = {
        ShortQuestionSubmitHandler,
        LongQuestionSubmitHandler,
        DropdownQuestionSubmitHandler,
        BooleanQuestionSubmitHandler,
        MatrixQuestionSubmitHandler,
        MatrixDropdownQuestionSubmitHandler,
        MatrixDynamicQuestionSubmitHandler,
        McqQuestionSubmitHandler,
        RatingQuestionSubmitHandler,
        FilesUploadingQuestionSubmitHandler,
        PhoneNumberQuestionSubmitHandler,
        ImagePickerQuestionSubmitHandler,
        EmailQuestionSubmitHandler,
        OpinionScaleQuestionSubmitHandler,
        CountryQuestionSubmitHandler,
        StateQuestionSubmitHandler,
        MessageQuestionSubmitHandler,
        DateQuestionSubmitHandler,
        NameQuestionSubmitHandler
    };

    return {
        ShortQuestionSubmitHandler,
        LongQuestionSubmitHandler,
        DropdownQuestionSubmitHandler,
        BooleanQuestionSubmitHandler,
        MatrixQuestionSubmitHandler,
        MatrixDropdownQuestionSubmitHandler,
        MatrixDynamicQuestionSubmitHandler,
        McqQuestionSubmitHandler,
        RatingQuestionSubmitHandler,
        FilesUploadingQuestionSubmitHandler,
        ImagePickerQuestionSubmitHandler,
        PhoneNumberQuestionSubmitHandler,
        EmailQuestionSubmitHandler,
        OpinionScaleQuestionSubmitHandler,
        CountryQuestionSubmitHandler,
        StateQuestionSubmitHandler,
        MessageQuestionSubmitHandler,
        NameQuestionSubmitHandler,
        DateQuestionSubmitHandler,
        SubmitHandlerFunctionMap
    };
};

export default useQuestionSubmitHandlerController;
