import createCache from '@emotion/cache';
import { CacheProvider } from '@emotion/react';

const cache = createCache({
    key: 'mui',
    prepend: true, // This is important to ensure MUI styles are injected first
    stylisPlugins: [
        // Plugin to add !important to all styles
        (element) => {
            if (element.type === 'rule') {
                element.props = element.props.map((prop) => (!prop.includes('!important') ? `${prop} !important` : prop));
            }
        }
    ]
});

// Wrap your app with this
// eslint-disable-next-line import/prefer-default-export, react/prop-types
export const EmotionProvider = ({ children }) => <CacheProvider value={cache}>{children}</CacheProvider>;
