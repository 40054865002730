import React, { useEffect, useReducer } from 'react';
import RegistrationService from 'services/registration.service';
import { LOGIN } from 'store/actions';
import axios from 'utils/axios';
import accountReducer from 'store/accountReducer';
import { useNavigate } from 'react-router-dom';

// constant
const initialState = {
    isLoggedIn: false,
    isInitialized: false,
    user: null
};

const GoogleSheetLoginHandler = () => {
    const [state, dispatch] = useReducer(accountReducer, initialState);

    const handleCallback = async () => {
        const surveyToken = window.localStorage.getItem('attemptedGoogleSheetSurveyToken');

        // window.location.search (get all query string from the url)
        const response = await RegistrationService.googleSheetCallbackRegistration(window.location.search, surveyToken);
        console.log(`I am here ${surveyToken}`, response);

        window.location.href = `/#/${surveyToken}/advance-form-create?tab=integration`;
    };

    useEffect(() => {
        handleCallback();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <>
            <h1>Integrating your google account! Please wait for a while...</h1>
        </>
    );
};

export default GoogleSheetLoginHandler;
