import React, { useState, useEffect } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { Elements, useStripe, useElements, PaymentElement } from '@stripe/react-stripe-js';
import axios from 'axios';
import PaymentService from 'services/payment.service';
import { Button, CircularProgress, Alert } from '@mui/material';
import PropTypes from 'prop-types';
import useAuth from 'hooks/useAuth';
import { IconFileDollar } from '@tabler/icons';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_CRED);

const CheckoutForm = ({ onPaymentSuccess, onPaymentError, amount }) => {
    const stripe = useStripe();
    const elements = useElements();
    const [error, setError] = useState(null);
    const [processing, setProcessing] = useState(false);
    const { user } = useAuth();

    const handleSubmit = async (event) => {
        event.preventDefault();

        if (!stripe || !elements) {
            return;
        }

        setProcessing(true);

        try {
            const { error, paymentIntent } = await stripe.confirmPayment({
                elements,
                confirmParams: {
                    return_url: 'http://localhost:3000/profile'
                },
                redirect: 'if_required'
            });

            if (error) {
                setError(error.message);
                onPaymentError(error.message);
            } else if (paymentIntent && paymentIntent.status === 'succeeded') {
                const { data } = await PaymentService.create({
                    user_id: user?.id,
                    amount: paymentIntent.amount / 100,
                    payment_method_id: null,
                    payment_type: 'connect',
                    confirmed: 1,
                    confirmed_on: new Date(),
                    deleted: 0,
                    deleted_at: null,
                    meta: JSON.stringify({ stripePayload: paymentIntent })
                });
                console.log('Payment succeeded: paymentIntent->', paymentIntent);
                onPaymentSuccess(paymentIntent);
            }
        } catch (err) {
            console.error('Payment error:', err);
            setError('An unexpected error occurred. Please try again.');
            onPaymentError('An unexpected error occurred. Please try again.');
        } finally {
            setProcessing(false);
        }
    };

    return (
        <form onSubmit={handleSubmit}>
            <PaymentElement />
            <Button
                type="submit"
                startIcon={<IconFileDollar />}
                size="large"
                variant="contained"
                sx={{ margin: '15px 0' }}
                disabled={processing}
            >
                {processing ? <CircularProgress size={24} /> : `Pay $${amount}`}
            </Button>
            {/* {error && (
                <Alert severity="error" sx={{ mt: 2 }}>
                    {error}
                </Alert>
            )} */}
        </form>
    );
};

CheckoutForm.propTypes = {
    onPaymentSuccess: PropTypes.func.isRequired,
    onPaymentError: PropTypes.func.isRequired,
    amount: PropTypes.number.isRequired
};

const Checkout = ({ onPaymentSuccess, onPaymentError, amount }) => {
    const [clientSecret, setClientSecret] = useState('');

    useEffect(() => {
        const fetchClientSecret = async () => {
            try {
                const response = await PaymentService.createInappPaymentIntent(amount * 100); // Convert to cents
                setClientSecret(response.data.clientSecret);
            } catch (error) {
                console.error('Error fetching client secret:', error);
                onPaymentError('Failed to initialize payment. Please try again.');
            }
        };

        fetchClientSecret();
    }, [amount, onPaymentError]);

    if (!clientSecret) {
        return <CircularProgress />;
    }

    return (
        <Elements stripe={stripePromise} options={{ clientSecret }}>
            <CheckoutForm onPaymentSuccess={onPaymentSuccess} onPaymentError={onPaymentError} amount={amount} />
        </Elements>
    );
};

Checkout.propTypes = {
    onPaymentSuccess: PropTypes.func,
    onPaymentError: PropTypes.func,
    amount: PropTypes.number
};

export default Checkout;
