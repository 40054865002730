import { Upload } from '@mui/icons-material';
import { Button, Grid } from '@mui/material';
import { isEmpty } from 'lodash';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import Emitter from 'utils/emitter';
import GeneralService from 'services/general.service';

const UploadImage = ({ formik }) => {
    // Default Value
    const [imageUrl, setImageUrl] = useState('');
    const { enqueueSnackbar } = useSnackbar();
    const [loaderOpen, setLoaderOpen] = React.useState(false);

    const valueSetter = (formik) => {
        if (formik && formik.values && isEmpty(formik.values?.meta.sectionImageUrl)) {
            // add
            formik.setFieldValue('meta.sectionImageUrl', imageUrl);
        } else if (!isEmpty(formik.values?.meta.sectionImageUrl)) {
            // edit
            setImageUrl(formik.values.meta.sectionImageUrl);
        }
    };

    Emitter.on('FORMIK_RENDERED', (formik) => valueSetter(formik));

    const handleChange = (event) => {
        formik.setFieldValue('meta.sectionImageUrl', event.target.value);
        setImageUrl(event.target.value);
    };

    const handleUploadClick = (event) => {
        setLoaderOpen(true);

        const file = event.target.files[0];
        const reader = new FileReader();
        if (file.size / 1024 / 1024 > 5) {
            setLoaderOpen(false);

            enqueueSnackbar('Image must be less than 5MB !', { variant: 'error', autoHideDuration: 3000 });
        } else {
            reader.onloadend = async () => {
                const fd = new FormData();
                fd.append('image', file);

                const { data: imageLink } = await GeneralService.storeImage(fd, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                });
                formik.setFieldValue('meta.sectionImageUrl', `${imageLink}`);
                setLoaderOpen(false);
            };

            reader.readAsDataURL(file);
        }
    };

    return (
        <>
            <Button startIcon={<Upload />} variant="outlined" component="label">
                Upload an image
                <input accept="image/*" id="upload" type="file" onChange={(event) => handleUploadClick(event)} hidden />
            </Button>
        </>
    );
};
UploadImage.propTypes = {
    formik: PropTypes.object
};
export default UploadImage;
