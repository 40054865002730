import React, { useState, useEffect } from 'react';
import { useFormik } from 'formik';
import * as yup from 'yup';
import {
    Alert,
    AlertTitle,
    Button,
    Card,
    CardContent,
    CardHeader,
    CircularProgress,
    Container,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Grid,
    IconButton,
    LinearProgress,
    MenuItem,
    Paper,
    TextField,
    Typography
} from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { Save, Upload, Close as CloseIcon, ShoppingCart } from '@mui/icons-material';
import GeneralSettingsService from 'services/profile.service';
import GeneralService from 'services/general.service';
import { useSnackbar } from 'notistack';
import useStore from 'zustand_store/useStore';
import useAuth from 'hooks/useAuth';
import Slider from '@mui/material/Slider';
import { Box } from '@mui/system';
import BuyMoreModal from './BuyMoreModal';
import { useNavigate } from 'react-router-dom';

const validationSchema = yup.object({
    first_name: yup.string().required('First name is required'),
    last_name: yup.string().required('Last Name is required'),
    email: yup.string('Enter your email').email('Enter a valid email').required('Email is required'),
    gender: yup.string().required('Gender is required'),
    date_of_birth: yup
        .date()
        .transform((v) => (v instanceof Date && !Number.isNaN(v) ? v : null))
        .required('Date of Birth field is Required')
        .typeError('Date of Birth field is Required and must be in date format')
});

const MyAccount = () => {
    const { enqueueSnackbar } = useSnackbar();
    const [isLoading, setIsLoading] = useState(true);
    const [loaderOpen, setLoaderOpen] = useState(false);
    const [openModal, setOpenModal] = useState(false);
    const navigate = useNavigate();

    const [responseCount, setResponseCount] = useState(100);
    const [price, setPrice] = useState(10);

    const handleSliderChange = (event, newValue) => {
        setResponseCount(newValue);
        // Calculate price based on response count (you can adjust this formula as needed)
        setPrice(Math.round((newValue / 10) * 1 * 100) / 100);
    };

    const handlePurchase = () => {
        // Implement purchase logic here
        console.log(`Purchasing ${responseCount} responses for $${price}`);
        setTimeout(() => {
            window.location.reload();
        }, 1000);
        // setOpenModal(false);
        // You might want to update the user's response quota here and show a success message
    };

    const handleOpenModal = () => {
        setOpenModal(true);
    };

    const handleCloseModal = () => {
        setOpenModal(false);
    };

    const { logout, user } = useAuth();
    const [responseFilled, setResponseFilled] = useState(user?.meta?.response_filled ?? 0);
    const [totalResponseRemained, setTotalResponseRemained] = useState(
        (user?.meta?.response_remained ?? 0) + (user?.meta?.response_filled ?? 0)
    );

    const [gender, setGender] = React.useState([
        {
            label: 'Male',
            value: 'm'
        },
        {
            label: 'Female',
            value: 'f'
        }
    ]);

    const [generalSetting, setGeneralSetting] = React.useState({
        first_name: '',
        last_name: '',
        email: '',
        gender: '',
        date_of_birth: '',
        subscribed: false
    });

    const fetchData = async () => {
        const { data } = await GeneralSettingsService.get();
        setGeneralSetting(data);
        setIsLoading(false);
    };

    useEffect(() => {
        fetchData();
    }, []);

    const formik = useFormik({
        initialValues: generalSetting,
        enableReinitialize: true,
        validationSchema,
        onSubmit: async (values) => {
            values.subscribed = values?.subscribed?.subscribed ? 1 : 0;
            const response = await GeneralSettingsService.update(values);
            enqueueSnackbar('Profile Updated Successfully!', { variant: 'success', autoHideDuration: 3000 });
            useStore.setState({ userEditDrawerOpen: false });
        }
    });

    const handleUploadClick = (event) => {
        const file = event.target.files[0];
        const reader = new FileReader();
        if (file.size / 1024 / 1024 > 5) {
            enqueueSnackbar('Image must be less than 5MB !', { variant: 'error', autoHideDuration: 3000 });
        } else {
            setLoaderOpen(true);
            reader.onloadend = async () => {
                const fd = new FormData();
                fd.append('image', file);

                const { data: imageLink } = await GeneralService.storeImage(fd, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                });
                formik.setFieldValue('meta.logo', `${imageLink}`);
                setLoaderOpen(false);
            };

            reader.readAsDataURL(file);
        }
    };

    if (isLoading) {
        return <CircularProgress />;
    }

    return (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
            <Grid container spacing={8}>
                <Grid item xs={12} md={5}>
                    <Card>
                        <CardHeader title="My Account" />
                        <CardContent>
                            <form onSubmit={formik.handleSubmit}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <TextField
                                            fullWidth
                                            id="first_name"
                                            name="first_name"
                                            label="First Name"
                                            value={formik.values.first_name}
                                            onChange={formik.handleChange}
                                            error={formik.touched.first_name && Boolean(formik.errors.first_name)}
                                            helperText={formik.touched.first_name && formik.errors.first_name}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            fullWidth
                                            id="last_name"
                                            name="last_name"
                                            label="Last Name"
                                            value={formik.values.last_name}
                                            onChange={formik.handleChange}
                                            error={formik.touched.last_name && Boolean(formik.errors.last_name)}
                                            helperText={formik.touched.last_name && formik.errors.last_name}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            fullWidth
                                            id="email"
                                            name="email"
                                            label="Email"
                                            value={formik.values.email}
                                            onChange={formik.handleChange}
                                            error={formik.touched.email && Boolean(formik.errors.email)}
                                            helperText={formik.touched.email && formik.errors.email}
                                            disabled
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            fullWidth
                                            id="gender"
                                            name="gender"
                                            select
                                            label="Gender"
                                            value={formik.values.gender}
                                            onChange={formik.handleChange}
                                            error={formik.touched.gender && Boolean(formik.errors.gender)}
                                            helperText={formik.touched.gender && formik.errors.gender}
                                        >
                                            {gender.map((option) => (
                                                <MenuItem key={option.value} value={option.value}>
                                                    {option.label}
                                                </MenuItem>
                                            ))}
                                        </TextField>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <DatePicker
                                            label="Date of Birth"
                                            value={formik.values.date_of_birth}
                                            onChange={(value) => formik.setFieldValue('date_of_birth', value)}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    fullWidth
                                                    error={formik.touched.date_of_birth && Boolean(formik.errors.date_of_birth)}
                                                    helperText={formik.touched.date_of_birth && formik.errors.date_of_birth}
                                                />
                                            )}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Button variant="outlined" component="label" startIcon={<Upload />} fullWidth>
                                            Upload a logo
                                            <input type="file" hidden onChange={handleUploadClick} />
                                        </Button>
                                    </Grid>
                                    {formik.values.meta?.logo && (
                                        <Grid item xs={12}>
                                            <img src={formik.values.meta.logo} alt="Logo" style={{ maxWidth: '100%', height: 'auto' }} />
                                        </Grid>
                                    )}
                                    <Grid item xs={12}>
                                        <Button type="submit" variant="contained" color="primary" startIcon={<Save />} fullWidth>
                                            Save Changes
                                        </Button>
                                    </Grid>
                                </Grid>
                            </form>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={12} md={5}>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <Card>
                                <CardHeader title="Your Responses" />
                                <CardContent>
                                    <Typography variant="body2" gutterBottom>
                                        Responses quota used ({responseFilled}/{totalResponseRemained})
                                    </Typography>
                                    <LinearProgress
                                        variant="determinate"
                                        value={(responseFilled / totalResponseRemained) * 100}
                                        sx={{ height: 10, borderRadius: 5 }}
                                    />
                                    <Button variant="text" color="primary" fullWidth sx={{ mt: 2 }} onClick={handleOpenModal}>
                                        Buy More Responses
                                    </Button>
                                </CardContent>
                            </Card>
                        </Grid>
                        <Grid item xs={12}>
                            <Card>
                                <CardHeader title="Subscription Settings" />
                                <CardContent>
                                    {generalSetting?.subscription?.subscribed && !generalSetting?.subscription?.incomplete ? (
                                        <Alert severity="success">
                                            <AlertTitle>Active Subscription</AlertTitle>
                                            Plan: <strong>{generalSetting.subscription.current_plan}</strong>
                                        </Alert>
                                    ) : (
                                        <>
                                            {generalSetting?.subscription?.incomplete && (
                                                <Alert severity="warning">
                                                    <AlertTitle>Important!</AlertTitle>
                                                    You have an incomplete payment. Click the upgrade button to complete the payment.
                                                </Alert>
                                            )}
                                            {!generalSetting.subscription?.subscribed && (
                                                <Alert severity="error">
                                                    <AlertTitle>No Active Subscription</AlertTitle>
                                                    You are not subscribed to any plan currently. Use the upgrade button to subscribe.
                                                </Alert>
                                            )}
                                        </>
                                    )}
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        fullWidth
                                        sx={{ mt: 2 }}
                                        onClick={() => navigate('packages')}
                                    >
                                        Upgrade
                                    </Button>
                                </CardContent>
                            </Card>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <BuyMoreModal
                responseCount={responseCount}
                handleSliderChange={handleSliderChange}
                openModal={openModal}
                price={price}
                handlePurchase={handlePurchase}
                handleCloseModal={handleCloseModal}
            />
        </LocalizationProvider>
    );
};

export default MyAccount;
