import ReactDOM from 'react-dom';
import { EmotionProvider } from './emotionConfig';

// third party
import { BrowserRouter, HashRouter, Router } from 'react-router-dom';
import { Provider } from 'react-redux';
// import { PersistGate } from 'redux-persist/integration/react';

// project imports
import App from 'App';
import { BASE_PATH } from 'config';
import { store, persister } from 'store';
// import * as serviceWorker from 'serviceWorker-old';
// import reportWebVitals from 'reportWebVitals';
import { ConfigProvider } from 'contexts/ConfigContext';
import { GlobalHelpProvider } from 'contexts/GlobalModalContext';
import { SnackbarProvider } from 'notistack';
import { ConfirmProvider } from 'material-ui-confirm';

// style + assets
import 'assets/scss/style.scss';
import { SnackbarUtilsConfigurator } from 'utils/SnackbarUtils';

// ==============================|| REACT DOM RENDER  ||============================== //

ReactDOM.render(
    <Provider store={store}>
        {/* <PersistGate loading={null} persistor={persister}> */}
        <ConfigProvider>
            <HashRouter basename={BASE_PATH}>
                <GlobalHelpProvider>
                    <ConfirmProvider>
                        <SnackbarProvider maxSnack={3}>
                            <SnackbarUtilsConfigurator />
                            <App />
                        </SnackbarProvider>
                    </ConfirmProvider>
                </GlobalHelpProvider>
            </HashRouter>
        </ConfigProvider>
        {/* </PersistGate> */}
    </Provider>,
    document.getElementById('root')
);
