/**
 * axios setup to use mock service
 */

import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import SnackbarUtils from 'utils/SnackbarUtils';

const axiosServices = axios.create();

const errorHandler = (error) => {
    //  If the app is in maintenance mode
    if (error.response?.status === 503 && error.response?.data?.maintenance) {
        window.location.href = '/#/maintenance';
    }

    if (error.response.status === 404) {
        window.location.href = '/#/404';
    }

    if (error.response.status === 422) {
        if (error?.response?.data?.errors) {
            Object.values(error.response.data.errors).map((err) => {
                SnackbarUtils.error(err);
                return null;
            });
        } else if (error?.response?.data?.message) {
            SnackbarUtils.error(error.response.data.message);
            // Object.values(error.response.data.errors).map((error) => {
            //     error.map((singleError) => SnackbarUtils.error(singleError));
            //     return null;
            // });
        } else {
            Object.values(error.response.data).map((err) => {
                err.map((singleError) => SnackbarUtils.error(singleError));
                return null;
            });
        }
    }
    return Promise.reject((error.response && error.response.data) || 'Wrong Services');
};

// interceptor for http
axiosServices.interceptors.response.use(
    (response) => response,
    // (error) => Promise.reject((error.response && error.response.data) || 'Wrong Services')
    (error) => errorHandler(error)
);

export default axiosServices;
