import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types'; // Add this line
import { Alert, AlertTitle, Button, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

const DismissibleAlert = ({ severity, title, children, action }) => {
    const [isVisible, setIsVisible] = useState(true);

    useEffect(() => {
        const alertState = localStorage.getItem('alertDismissed');
        if (alertState === 'true') {
            setIsVisible(false);
        }
    }, []);

    const handleDismiss = () => {
        setIsVisible(false);
        localStorage.setItem('alertDismissed', 'true');
    };

    if (!isVisible) return null;

    return (
        <Alert
            severity={severity}
            sx={{
                mb: 2,
                p: '0 20px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between'
            }}
            action={
                <>
                    <IconButton aria-label="close" color="inherit" size="small" onClick={handleDismiss}>
                        <CloseIcon fontSize="inherit" />
                    </IconButton>
                </>
            }
        >
            <AlertTitle sx={{ mt: 1 }}>
                {title} {action}
            </AlertTitle>
            {children}
        </Alert>
    );
};

// Add this block to validate props
DismissibleAlert.propTypes = {
    severity: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    children: PropTypes.node,
    action: PropTypes.node
};

export default DismissibleAlert;
